.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: #fff;
    padding: 32px;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 400px;
    text-align: center;
    position: relative;
  }
  
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  .phoneInput {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    outline: none;
    transition: all 0.3s ease-in-out;
    background: #f8f8f8;
  }
  
  .phoneInput:focus {
    background: #fff;
  }
  
  .error {
    font-size: 0.9rem;
    margin-top: 5px;
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 24px;
  }
  
  .submitButton {
    color: #fff;
    border: none;
    padding: 12px 18px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .cancelButton {
    background: none;
    padding: 12px 18px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  @media (max-width: 480px) {
    .modalContent {
      width: 90%;
      padding: 24px;
    }
  
    h3 {
      font-size: 1.3rem;
    }
  
    .buttonGroup {
      flex-direction: column;
    }
  
    .submitButton,
    .cancelButton {
      width: 100%;
    }
  }