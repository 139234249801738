.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding-top: 26px;
  padding-bottom: 26px;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 33px;
  cursor: pointer;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 33px;
  cursor: pointer;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

/**
   * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
   * However, since the length of pageNumberList can vary up to 7,
   * we need to keep track of how much space is allocated for the list
   * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
   */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.paginationGap {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--colorGrey700);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}

.pageNumberList {
  display: flex;
  justify-content: center;
  gap: 12px;
  max-width: 100%;
  padding-bottom: 5px;
}

.toPageLink {
  padding: 10px 14px;
  border-radius: 50%;
  color: var(--colorGrey700);
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.toPageLink:hover {
  background-color: var(--colorGrey200);
  text-decoration: none;
  cursor: pointer;
}

.currentPage {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  font-weight: bold;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .pageNumberList {
    gap: 2px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;
  }

  .toPageLink,
  .currentPage {
    width: 24px;
    height: 24px;
    font-size: 12px;
    padding: 2px;
  }
}

@media (max-width: 480px) {
  .pageNumberList {
    gap: 1px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;
  }

  .toPageLink,
  .currentPage {
    width: 20px;
    height: 20px;
    font-size: 10px;
    padding: 1px;
  }
}