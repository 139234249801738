@import '../../../styles/customMediaQueries.css';

.root {
  border-radius: 2px;
}

.searchInputIcon {
  box-sizing: content-box;
  padding-left: 24px;
  margin-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.searchInput {
  flex-grow: 1;
  height: 69px;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border: 0;
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border: 0;
    outline: none;
    box-shadow: unset;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }
}

.searchPredictions {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 16px;
  margin-bottom: 102px;

  @media (--viewportXLarge) {
    margin-bottom: 0;
  }
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.keywordInput {
  height: 69px;
  border: 0;

  &:hover,
  &:focus {
    outline: none;
    border: 0;
    box-shadow: none;
  }
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;
  pointer-events: none;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
